import { Link } from '@/components/Link';
import { Stack } from '@magicschool/ui/Stack';
import { Tooltip } from '@magicschool/ui/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { CenteredBox } from 'components/styled/Boxes';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
const UnmemoizedSpotlight = () => <Tooltip title={<Stack direction="row" alignItems="center">
        <OpenInNewIcon fontSize="inherit" sx={{
    marginBottom: 0,
    marginRight: 1,
    padding: 0
  }} />
        <FormattedMessage id="tooltip.magic_store" />
      </Stack>} placement="top" sx={{
  alignItems: 'center',
  justifyContent: 'center'
}} data-sentry-element="Tooltip" data-sentry-component="UnmemoizedSpotlight" data-sentry-source-file="Spotlight.tsx">
    <Link href="https://magicschoolshop.com" rel="noopener noreferrer" target="_blank" data-sentry-element="Link" data-sentry-source-file="Spotlight.tsx">
      <CenteredBox sx={{
      flexDirection: 'column',
      borderRadius: 2,
      '&:hover': {
        boxShadow: 3
      }
    }} data-sentry-element="CenteredBox" data-sentry-source-file="Spotlight.tsx">
        <img width={220} height={102} src="/teachers-are-magic2.svg" title="Teachers are magic banner" aria-label="Teachers are magic banner animation"></img>
      </CenteredBox>
    </Link>
  </Tooltip>;
export const Spotlight = memo(UnmemoizedSpotlight);