import { Link } from '@/components/Link';
import { useAuth } from '@/hooks/useAuth';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { subscriptionPlans } from '@magicschool/supabase/user/utils';
import { Box } from '@magicschool/ui/Box';
import { Chip } from '@magicschool/ui/Chip';
import { Tooltip } from '@magicschool/ui/Tooltip';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
const SubscriptionLink = () => {
  return <Link href="/subscription" target="_blank" style={{
    color: 'white',
    textDecoration: 'underline'
  }} data-sentry-element="Link" data-sentry-component="SubscriptionLink" data-sentry-source-file="PlanTag.tsx">
      <FormattedMessage id="plan-tag.learn-more" data-sentry-element="FormattedMessage" data-sentry-source-file="PlanTag.tsx" />
    </Link>;
};
type TagDetails = {
  id: string;
  labelId: string;
  description: ({
    trialDaysLeft
  }: {
    trialDaysLeft: number;
  }) => ReactNode;
  condition: ({
    isPlusUser,
    isEnterpriseUser,
    trialDaysLeft,
    canSubscribe
  }: {
    isPlusUser: boolean;
    isEnterpriseUser: boolean;
    trialDaysLeft: number;
    canSubscribe: boolean;
  }) => boolean;
};
const tagDetails: TagDetails[] = [{
  id: 'free',
  labelId: 'plan-tag.name.free',
  description: () => <>
        <FormattedMessage id="plan-tag.description.free" /> <SubscriptionLink />
      </>,
  condition: ({
    isPlusUser,
    isEnterpriseUser
  }) => !isPlusUser && !isEnterpriseUser
}, {
  id: 'plus-trial',
  labelId: 'plan-tag.name.plus-trial',
  description: ({
    trialDaysLeft
  }) => <>
        <FormattedMessage id="plan-tag.description.plus-trial" values={{
      trialDaysLeft
    }} />{' '}
        <SubscriptionLink />
      </>,
  condition: ({
    isPlusUser,
    trialDaysLeft,
    canSubscribe
  }) => isPlusUser && canSubscribe && trialDaysLeft > 0
}, {
  id: 'plus',
  labelId: 'plan-tag.name.plus',
  description: () => <>
        <FormattedMessage id="plan-tag.description.plus" /> <SubscriptionLink />
      </>,
  condition: ({
    isPlusUser,
    trialDaysLeft
  }) => isPlusUser && trialDaysLeft === 0
}, {
  id: 'enterprise',
  labelId: 'plan-tag.name.enterprise',
  description: () => <FormattedMessage id="plan-tag.description.enterprise" />,
  condition: ({
    isEnterpriseUser
  }) => isEnterpriseUser
}];
export const PlanTag = () => {
  const {
    userData,
    orgId
  } = useAuth();
  const {
    trialAccess
  } = useFeatureFlags();
  const isPlusUser = userData?.plan === subscriptionPlans.PLUS || trialAccess;
  const isEnterpriseUser = Boolean(orgId);
  const {
    canSubscribe,
    trialDaysLeft
  } = useFeatureFlags();
  const tagDetail = tagDetails.find(tag => tag.condition({
    isPlusUser,
    isEnterpriseUser,
    trialDaysLeft,
    canSubscribe
  }));
  if (!tagDetail) {
    return null;
  }
  return <Tooltip title={tagDetail.description({
    trialDaysLeft
  })} data-sentry-element="Tooltip" data-sentry-component="PlanTag" data-sentry-source-file="PlanTag.tsx">
      <Box sx={{
      width: '100%',
      textAlign: 'center',
      mt: 1,
      mb: 4
    }} data-sentry-element="Box" data-sentry-source-file="PlanTag.tsx">
        <Chip sx={{
        width: '75%'
      }} label={<FormattedMessage id={tagDetail.labelId} />} data-sentry-element="Chip" data-sentry-source-file="PlanTag.tsx" />
      </Box>
    </Tooltip>;
};