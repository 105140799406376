import { radius } from '@magicschool/ui/constants/radius';
import { space } from '@magicschool/ui/constants/space';
import { convertCssObjectToRtlConditionally } from '@magicschool/ui/utils';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
export const LeftAlignedButton = styled(Button)({
  justifyContent: 'flex-start'
});
export const BoldButton = styled(Button)(({
  theme
}) => ({
  fontWeight: 700,
  color: theme.palette.secondary.main
}));
export const NavButton = styled(Button)(({
  theme
}) => convertCssObjectToRtlConditionally({
  color: '#444',
  minWidth: '46px',
  justifyContent: 'left',
  paddingTop: space(1.5),
  paddingBottom: space(1.5),
  borderRadius: radius[1],
  ':hover': {
    color: '#4b02e5',
    backgroundColor: '#f6e5ff'
  }
}, theme)) as typeof Button;