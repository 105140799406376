import type { MagicSchoolUser } from '@magicschool/supabase/user';

// verify email format
const EMAIL_REGEX = /^[\w.%+'-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const isValidEmail = (email: string | undefined | null) => {
  return EMAIL_REGEX.test(email ?? '');
};

export const isValidOrgUser = (user: MagicSchoolUser, orgId: string): boolean => {
  if (user.user_role === 'internal' || user.user_role === 'superuser') return true;
  if (user.user_role === 'org_admin' && user.org_id === orgId) return true;
  return false;
};
