import { FormattedMessage } from 'react-intl';
import { Box } from '@magicschool/ui/Box';
import { Divider } from '@magicschool/ui/Divider';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
interface Props {
  titleId: string;
  open: boolean;
  onClose: () => void;
  videoUrl: string;
}
function VideoDialog({
  titleId,
  videoUrl,
  open,
  onClose
}: Props) {
  return <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth data-sentry-element="Dialog" data-sentry-source-file="VideoDialog.tsx">
        <DialogTitle sx={{
        textAlign: 'center'
      }} data-sentry-element="DialogTitle" data-sentry-source-file="VideoDialog.tsx">{<FormattedMessage id={titleId} />}</DialogTitle>
        <Divider data-sentry-element="Divider" data-sentry-source-file="VideoDialog.tsx" />
        <DialogContent sx={{
        padding: 1
      }} data-sentry-element="DialogContent" data-sentry-source-file="VideoDialog.tsx">
          <Box display="flex" height="384px" alignItems="center" justifyContent="center" data-sentry-element="Box" data-sentry-source-file="VideoDialog.tsx">
            <iframe width="90%" height="100%" src={videoUrl} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </Box>
        </DialogContent>
        <DialogActions data-sentry-element="DialogActions" data-sentry-source-file="VideoDialog.tsx">
          <Button color="secondary" onClick={onClose} data-test-id={`close-${titleId}-button`} data-sentry-element="Button" data-sentry-source-file="VideoDialog.tsx">
            <FormattedMessage id="close" data-sentry-element="FormattedMessage" data-sentry-source-file="VideoDialog.tsx" />
          </Button>
        </DialogActions>
      </Dialog>
    </>;
}
export default VideoDialog;