import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';

// third-party
import { FormattedMessage } from 'react-intl';

// project imports
import MainCard from '@/components/ui-component/cards/MainCard';
import Transitions from '@/components/ui-component/extended/Transitions';
import { zIndices } from '@magicschool/ui/constants/zIndices';
import useFeatureFlags from 'hooks/useFeatureFlags';
// assets
import { useRouter } from 'next/navigation';
import { useAuth } from '@/hooks/useAuth';
import { analyticsTrack } from '@/util/analytics';
import { logger } from '@magicschool/logger';
import { Avatar } from '@magicschool/ui/Avatar';
import { Box } from '@magicschool/ui/Box';
import { Chip } from '@magicschool/ui/Chip';
import { Paper } from '@magicschool/ui/Paper';
import { Stack } from '@magicschool/ui/Stack';
import { Text } from '@magicschool/ui/Text';
import { radius } from '@magicschool/ui/constants/radius';
import { space } from '@magicschool/ui/constants/space';
import { getBuildPrefix } from '@magicschool/utils/nextjs/metadata';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popper from '@mui/material/Popper';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const router = useRouter();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const {
    supabaseUser,
    signOut,
    userData
  } = useAuth();
  // TODO: not being updated!
  const [open, setOpen] = useState(false);
  const {
    disabledSubscriptions
  } = useFeatureFlags();
  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const anchorRef = useRef<any>();
  const handleLogout = async () => {
    try {
      analyticsTrack('Navigation:Logout');
      await signOut();
    } catch (err) {
      console.error(err);
    }
  };
  const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
    if (anchorRef.current?.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number, route = '') => {
    setSelectedIndex(index);
    handleClose(event);
    router.push(route);
  };
  const handleToggle = () => {
    analyticsTrack('Navigation:Action', {
      location: 'settings',
      value: 'Settings'
    });
    setOpen(prevOpen => !prevOpen);
  };
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  const handleManageSubscriptions = (event: React.MouseEvent<HTMLDivElement>) => {
    logger.debug(`handleManageSubscriptions:${process.env.NEXT_PUBLIC_CUSTOMER_PORTAL_URL}`);
    handleClose(event);
    window.open(process.env.NEXT_PUBLIC_CUSTOMER_PORTAL_URL, '_blank');
    analyticsTrack('Subscription:Manage', {
      location: 'profile',
      text: 'Manage Subscriptions'
    });
  };
  return <>
      <Chip id="menu-list-grow" sx={{
      height: '48px',
      alignItems: 'center',
      borderRadius: radius.round,
      transition: 'all .2s ease-in-out',
      borderColor: theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.light,
      '&[aria-controls="menu-list-grow"], &:hover': {
        borderColor: theme.palette.secondary.main,
        background: `${theme.palette.secondary.main}!important`,
        color: theme.palette.secondary.light,
        '& svg': {
          stroke: theme.palette.secondary.light
        }
      },
      '& .MuiChip-label': {
        lineHeight: 0
      }
    }} icon={<Avatar src={userData?.profile_image || supabaseUser?.user_metadata?.avatar_url} size="medium" sx={{
      margin: `${space(1, 0, 1, 1)} !important`
    }} ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" title="Profile" color="ternary" alt="profile-image" />} label={<SettingsIcon sx={theme => ({
      stroke: theme.palette.secondary.main,
      strokeWidth: 2,
      fill: 'transparent'
    })} />} variant="outlined" ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" aria-label="user-account" onClick={handleToggle} color="primary" data-sentry-element="Chip" data-sentry-source-file="index.tsx" />
      <Popper placement="bottom-end" open={open} anchorEl={anchorRef.current} role={undefined} transition sx={{
      zIndex: zIndices.overlay
    }} modifiers={[{
      name: 'offset',
      options: {
        offset: [0, 14]
      }
    }]} data-sentry-element="Popper" data-sentry-source-file="index.tsx">
        {({
        TransitionProps
      }) => <ClickAwayListener onClickAway={handleClose}>
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                {open && <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                    <Box sx={{
                p: 2,
                pb: 0
              }}>
                      <Stack>
                        <Stack direction="column" spacing={0.5} alignItems={theme.direction === 'rtl' ? 'right' : 'left'}>
                          {userData?.name && <Text variant="heading-4">{userData?.name}</Text>}
                          <Text variant="body-1">{userData?.email}</Text>
                        </Stack>
                      </Stack>
                    </Box>

                    <Box sx={{
                p: 2,
                pt: 0
              }}>
                      <List component="nav" sx={{
                  width: '100%',
                  maxWidth: 350,
                  minWidth: 300,
                  backgroundColor: theme.palette.background.paper,
                  [theme.breakpoints.down('md')]: {
                    minWidth: '100%'
                  },
                  '& .MuiListItemButton-root': {
                    mt: 0.5
                  }
                }}>
                        <ListItemButton sx={{
                    borderRadius: radius[1]
                  }} selected={selectedIndex === 0} onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    handleListItemClick(event, 0, '/profile');
                    analyticsTrack('Navigation:Action', {
                      location: 'settings',
                      value: 'Profile Settings'
                    });
                  }}>
                          <ListItemIcon>
                            <SettingsOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary={<Text variant="body-2" textAlign={theme.direction === 'rtl' ? 'right' : 'left'}>
                                <FormattedMessage id="user_profile_settings" />
                              </Text>} />
                        </ListItemButton>

                        {!disabledSubscriptions && userData?.stripe_customer?.stripe_customer_id && <ListItemButton sx={{
                    borderRadius: radius[1]
                  }} selected={selectedIndex === 2} onClick={handleManageSubscriptions}>
                            <ListItemIcon>
                              <CreditCardIcon />
                            </ListItemIcon>
                            <ListItemText primary={<Text variant="body-2" textAlign={theme.direction === 'rtl' ? 'right' : 'left'}>
                                  <FormattedMessage id="profile.manage_subscriptions" />
                                </Text>} />
                          </ListItemButton>}
                        <ListItemButton sx={{
                    borderRadius: radius[1]
                  }} onClick={handleLogout}>
                          <ListItemIcon>
                            <LogoutIcon />
                          </ListItemIcon>
                          <ListItemText primary={<Text variant="body-2" textAlign={theme.direction === 'rtl' ? 'right' : 'left'}>
                                <FormattedMessage id="logout" />
                              </Text>} />
                        </ListItemButton>
                        <ListItemText>
                          <Text variant="caption-1" textAlign="center" color="secondary">
                            build : {getBuildPrefix()}
                          </Text>
                        </ListItemText>
                      </List>
                    </Box>
                  </MainCard>}
              </Paper>
            </Transitions>
          </ClickAwayListener>}
      </Popper>
    </>;
};
export default ProfileSection;